<template>
  <div>
    <footer class="footer">
      <div class="container">
        <div class="row">
          <div class="col-md-5 col-lg-4 col-xl-4">
            <div class="footer-box">
              <div class="logo-box mb-3">
                <img
                  src="../assets/logo.png"
                  alt="logo"
                  class="img-fluid"
                  style="max-width: 150px"
                />
              </div>
              <!--logo-box-->

              <div class="company-info">
                <p class="mb-0">
                  Elevate your crypto experience with the GDCC Wallet App.
                  Seamlessly manage and secure your digital assets. Advanced
                  encryption, intuitive design, and real-time monitoring ensure
                  your peace of mind.
                </p>
              </div>
            </div>
            <!--footer-box-->
          </div>
          <!--col-md-4 col-lg-4 col-xl-4-->

          <div class="col-md-7 col-lg-8 col-xl-7 offset-xl-1">
            <div class="row inner-row">
              <div
                class="col-md-4 col-lg-4 col-xl-4 mb-4 mb-md-0 mb-lg-0 mb-xl-0"
              >
                <div class="footer-link-box">
                  <h3 class="mb-0 pb-3">Useful Links</h3>

                  <ul class="link-list">
                    <li>
                      <a href="#home">Home</a>
                    </li>
                    <li>
                      <a href="#about">About</a>
                    </li>
                    <li>
                      <a href="#feature">Feature</a>
                    </li>
                    <li>
                      <a href="#benefit">Benefits</a>
                    </li>
                    <li>
                      <a href="#contact">Contact Us</a>
                    </li>
                  </ul>
                </div>
              </div>

              <div
                class="col-md-4 col-lg-4 col-xl-4 mb-4 mb-md-0 mb-lg-0 mb-xl-0"
              >
                <div class="footer-link-box">
                  <h3 class="mb-0 pb-3">Community</h3>

                  <ul class="link-list">
                    <li>
                      <a href="#">Help Center</a>
                    </li>
                    <li>
                      <a href="#">Partners</a>
                    </li>
                    <li>
                      <a href="#">Suggestions</a>
                    </li>
                    <li>
                      <a href="#">Benefits</a>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="col-md-4 col-lg-4 col-xl-4">
                <div class="footer-link-box">
                  <h3 class="mb-0 pb-3">Policy</h3>

                  <ul class="link-list">
                    <li>
                      <a href="#">Terms and conditions</a>
                    </li>
                    <li>
                      <a href="#">Privacy policy</a>
                    </li>
                    <li>
                      <a href="#">Newsletters</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--row-->

        <div class="col-md-12 col-lg-12 col-xl-12">
          <div class="copyright-text">
            <p>Copyright © | GDCCDAPP</p>
          </div>
        </div>
      </div>
      <!--container-->
    </footer>
    <!--footer-->
  </div>
</template>

<script>
export default {
  name: "FooterComponent",
};
</script>

<style scoped>
footer.footer {
  padding: 40px 0;
  background-color: #141414;
  padding-bottom: 0;
}

.company-info p {
  font-size: 15px;
  font-weight: 500;
  color: #c0c0c0;
  line-height: 26px;
}

.footer-link-box h3 {
  color: #fff;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 600;
}

.footer-link-box ul.link-list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.footer-link-box ul.link-list li {
  margin-bottom: 10px;
}

.footer-link-box ul.link-list li a {
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  color: #c0c0c0;
  transition: all 0.5s ease;
}

.footer-link-box ul.link-list li a:hover {
  transition: all 0.5s ease;
  color: #fff;
}

.copyright-text {
  padding: 10px 0px;
  text-align: center;
  padding-top: 20px;
  border-top: 2px solid #2f2f2f;
  margin-top: 17px;
  padding-bottom: 15px;
}

.copyright-text p {
  margin: 0;
  font-size: 15px;
  font-weight: 500;
  color: #c0c0c0;
}

@media all and (min-width: 768px) and (max-width: 991px) {
  .footer-link-box h3 {
    font-size: 15px;
  }
}

@media all and (min-width: 320px) and (max-width: 767px) {
  .row.inner-row {
    margin-top: 25px;
  }
}
</style>
