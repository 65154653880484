<template>
  <div>
    <header class="head">
      <nav class="navbar navbar-expand-lg p-0">
        <div class="container">
          <a class="navbar-brand" href="#"
            ><img
              src="../assets/logo.png"
              alt="logo"
              class="img-fluid"
              style="max-width: 130px"
          /></a>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
              <li class="nav-item">
                <a class="nav-link active" aria-current="page" href="#home">Home</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#about">About</a>
              </li>

              <li class="nav-item">
                <a class="nav-link" href="#feature">Features</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#benefits">Benefits</a>
              </li>

              <li class="nav-item">
                <a class="nav-link" href="#contact">Contact Us</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
    <!--head-->
  </div>
</template>

<script>
export default {
  name: "HeaderComponents",
};
</script>

<style scoped>
ul.navbar-nav {
  gap: 20px;
}

ul.navbar-nav li.nav-item a.nav-link {
  color: #fff;
  font-size: 15px;
  font-weight: 600;
}
</style>
