<template>
  <div>
    <Header />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
export default {
  name: "App",

  components: {
    Header,
    Footer,
  },
};
</script>

<style>
body {
  background: #060606 !important;
}

#app {
  font-family: Manrop, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: Manrop;
  src: url(./assets/fonts/Manrope-Regular.ttf);
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: Manrop;
  src: url(./assets/fonts/Manrope-Medium.ttf);
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: Manrop;
  src: url(./assets/fonts/Manrope-SemiBold.ttf);
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: Manrop;
  src: url(./assets/fonts/Manrope-ExtraBold.ttf);
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: Manrop;
  src: url(./assets/fonts/Manrope-Bold.ttf);
  font-weight: 800;
  font-display: swap;
}

section {
  padding: 80px 0;
}
</style>
