<template>
  <div>
    <section class="banner-sec" id="home">
      <div class="container">
        <div class="row">
          <div class="col-md-6 col-lg-6 col-xl-6">
            <div class="banner-info">
              <h2 class="mb-0">
                Discover Unprecedented <br />
                Ways to Buy and <br />
                Trade Crypto.
              </h2>
              <p class="mb-0">
                Explore innovative methods to purchase and exchange <br />
                cryptocurrencies like never before. Experience a realm of <br />
                unprecedented possibilities in the world of digital asset
                trading.
              </p>

              <button class="btn btn-primary">Start Now</button>
            </div>
            <!--banner-info-->
          </div>
          <!--col-md-6 col-lg-6 col-xl-6-->

          <div class="col-md-6 col-lg-6 col-xl-6 order-first order-sm-1">
            <div class="banner-image">
              <img
                src="../assets/images/banner.svg"
                alt="banner"
                class="img-fluid"
              />
            </div>
            <!--banner-image-->
          </div>
          <!--col-md-6 col-lg-6 col-xl-6-->
        </div>
        <!--row-->
      </div>
      <!--container-->
    </section>
    <!--banner-sec-->

    <section class="about-sec" id="about">
      <div class="container">
        <div class="row align-items-center upper-row">
          <div class="col-md-6 col-lg-6 col-xl-6">
            <div class="wallet-info">
              <h2 class="mb-0">
                Elevate Your Crypto Storage<br />
                Wallet of the Future.
              </h2>

              <p class="mb-0">
                Explore innovative methods to purchase and exchange
                cryptocurrencies like <br />
                never before. Experience a realm of unprecedented possibilities
                in the world <br />
                of digital asset trading.
              </p>

              <ul class="list">
                <li>
                  <span
                    ><img
                      src="../assets/icon/check.png"
                      alt="check"
                      class="img-fluid"
                  /></span>

                  Cost-Effective Excellence
                </li>

                <li>
                  <span
                    ><img
                      src="../assets/icon/check.png"
                      alt="check"
                      class="img-fluid"
                  /></span>

                  Efficient and Safe Transactions
                </li>

                <li>
                  <span
                    ><img
                      src="../assets/icon/check.png"
                      alt="check"
                      class="img-fluid"
                  /></span>

                  Protected Encryption
                </li>
              </ul>
            </div>
            <!--wallet-info-->
          </div>
          <!--"col-md-6 col-lg-6 col-xl-6-->

          <div class="col-md-6 col-lg-6 col-xl-6 order-first order-sm-1">
            <div class="about-image text-center">
              <img
                src="../assets/images/about-1.png"
                alt="about"
                class="img-fluid"
              />
            </div>
          </div>
          <!--col-md-6 col-lg-6 col-xl-6-->
        </div>
        <!--row-->

        <div class="row align-items-center">
          <div class="col-md-6 col-lg-6 col-xl-6">
            <div class="about-image text-start">
              <img
                src="../assets/images/about-2.png"
                alt="about"
                class="img-fluid"
              />
            </div>
          </div>
          <!--col-md-6 col-lg-6 col-xl-6-->

          <div class="col-md-6 col-lg-6 col-xl-6">
            <div class="wallet-info">
              <h2 class="mb-0">
                Premium Defense <br />
                Architecture
              </h2>

              <p class="mb-0">
                Discover unyielding security through our Dapp's Premium Defense <br/>
                Architecture. Immerse yourself in a realm of utmost protection,
                where <br/> innovation and design converge to safeguard your digital
                assets effortlessly.
              </p>
            </div>
            <!--wallet-info-->

            <div class="card-info">
              <div class="row inner-row">
                <div class="col-md-6 col-lg-6 col-xl-6 border-box">
                  <div class="security-info">
                    <h3 class="mb-0 pb-2">0</h3>

                    <h4 class="mb-0">Multi-Layered Encryption</h4>
                  </div>
                  <!--security-info-->
                </div>
                <!--col-md-6 col-lg-6 col-xl-6-->

                <div class="col-md-6 col-lg-6 col-xl-6 border-box">
                  <div class="security-info">
                    <h3 class="mb-0 pb-2">0</h3>

                    <h4 class="mb-0">Biometric Authentication</h4>
                  </div>
                  <!--security-info-->
                </div>
                <!--col-md-6 col-lg-6 col-xl-6-->

                <div class="col-md-6 col-lg-6 col-xl-6 border-box">
                  <div class="security-info">
                    <h3 class="mb-0 pb-2">0</h3>

                    <h4 class="mb-0">Device Synchronization</h4>
                  </div>
                  <!--security-info-->
                </div>
                <!--col-md-6 col-lg-6 col-xl-6-->

                <div class="col-md-6 col-lg-6 col-xl-6 border-box">
                  <div class="security-info">
                    <h3 class="mb-0 pb-2">0</h3>

                    <h4 class="mb-0">Real-Time Monitoring</h4>
                  </div>
                  <!--security-info-->
                </div>
                <!--col-md-6 col-lg-6 col-xl-6-->
              </div>
            </div>
            <!--card-info-->
          </div>
          <!--"col-md-6 col-lg-6 col-xl-6-->
        </div>
        <!--row-->
      </div>
      <!--container-->
    </section>
    <!--about-sec-->
    <section class="feature-sec" id="feature">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-6 col-lg-6 col-xl-6">
            <div class="heading-info">
              <h2 class="mb-0">
                Fully featured to buy, <br />
                trade and invest in crypto.
              </h2>
            </div>
            <!--heading-info-->
          </div>
          <!--col-md-6 col-lg-6 col-xl-6-->

          <div class="col-md-6 col-lg-6 col-xl-6">
            <div class="button-box">
              <button class="btn btn-primary">Browse All Features</button>
            </div>
            <!--button-box-->
          </div>
          <!--col-md-6 col-lg-6 col-xl-6-->
        </div>
        <!--row-->

        <div class="inner-wrapper">
          <div class="row">
            <div class="col-md-12 col-lg-12 col-xl-8 feature-border">
              <div class="image-info-box">
                <div class="info-box">
                  <h3 class="mb-0">Invest in 100+ Digital Currencies</h3>
                  <p class="mb-0">
                    Diversify with over 100 digital currencies <br/> for your strategic investment endeavors.
                  </p>
                </div>
                <!--info-box-->

                <div class="image-box">
                  <img
                    src="../assets/images/features.png"
                    alt="feature"
                    class="img-fluid"
                  />
                </div>
              </div>
            </div>
            <!--col-md-12 col-lg-8 col-xl-8-->

            <div class="col-md-6 col-lg-6 col-xl-4 feature-border">
              <div class="feature-box">
                <div class="icon-box">
                  <img
                    src="../assets/icon/feature-icon-1.svg"
                    alt="feature-icon"
                    class="img-fluid"
                  />
                </div>
                <!--icon-box-->

                <div class="icon-info">
                  <h2 class="mb-0">Safe & Encrypted Digital Wallet</h2>

                  <p class="mb-0">
                    Ensure security with an encrypted digital wallet for safeguarded cryptocurrency storage.
                  </p>
                </div>
              </div>
            </div>
            <!--col-md-6 col-lg-4 col-xl-4-->

            <div class="col-md-6 col-lg-6 col-xl-4 feature-border">
              <div class="feature-box">
                <div class="icon-box">
                  <img
                    src="../assets/icon/feature-icon-2.svg"
                    alt="feature-icon"
                    class="img-fluid"
                  />
                </div>
                <!--icon-box-->

                <div class="icon-info">
                  <h2 class="mb-0">Two-Factor Authentication</h2>

                  <p class="mb-0">
                    Strengthen your security with an additional layer of authentication for access.
                  </p>
                </div>
              </div>
            </div>
            <!--col-md-6 col-lg-4 col-xl-4-->

            <div class="col-md-6 col-lg-6 col-xl-4 feature-border">
              <div class="feature-box">
                <div class="icon-box">
                  <img
                    src="../assets/icon/feature-icon-3.svg"
                    alt="feature-icon"
                    class="img-fluid"
                  />
                </div>
                <!--icon-box-->

                <div class="icon-info">
                  <h2 class="mb-0">Instant Transfers</h2>

                  <p class="mb-0">
                    Execute rapid and secure transfers between wallet users without delays.
                  </p>
                </div>
              </div>
            </div>
            <!--col-md-6 col-lg-4 col-xl-4-->

            <div class="col-md-6 col-lg-6 col-xl-4 feature-border">
              <div class="feature-box">
                <div class="icon-box">
                  <img
                    src="../assets/icon/feature-icon-4.svg"
                    alt="feature-icon"
                    class="img-fluid"
                  />
                </div>
                <!--icon-box-->

                <div class="icon-info">
                  <h2 class="mb-0">Market Insights</h2>

                  <p class="mb-0">
                    Stay informed with real-time market data and insights for informed investment decisions.
                  </p>
                </div>
              </div>
            </div>
            <!--col-md-4 col-lg-4 col-xl-4-->
          </div>
          <!--row-->
        </div>
        <!--inner-wrapper-->
      </div>
      <!--container-->
    </section>
    <!--feature-sec-->

    <section class="contact-sec" id="contact">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-6 col-lg-6 col-xl-6">
            <div class="cta-image">
              <img
                src="../assets/images/cta.png"
                alt="cta-icon"
                class="img-fluid"
              />
            </div>
            <!--cta-image-->
          </div>
          <!--col-md-6 col-lg-6 col-xl-6-->

          <div class="col-md-6 col-lg-6 col-xl-6">
            <div class="cta-info">
              <h2 class="mb-0">
                Preview the Future with <br/> Our Crypto Wallet Now.
              </h2>

              <p class="mb-0">
                Embrace innovation today by experiencing the Crypto Wallet of the future with us. Step into a realm of cutting-edge technology, security, and convenience. Our advanced wallet offers a sneak peek into tomorrow's digital finance landscape, empowering you to manage your cryptocurrencies with unmatched ease and confidence. Elevate your digital asset experience and be a part of the future, today.
              </p>

              <button class="btn btn-primary">Start Now</button>
            </div>
            <!--cta-info-->
          </div>
          <!--col-md-6 col-lg-6 col-xl-6-->
        </div>
        <!--row-->
      </div>
      <!--container-->
    </section>
    <!--contact-sec-->

    <section class="benefits-sec" id="benefits">
      <div class="container">
        <div class="row">
          <div
            class="col-md-6 col-lg-6 col-xl-4 mb-3"
            v-for="(benefit, index) in BenefitsData"
            :key="index"
          >
            <div class="benefits-box">
              <h3 class="mb-0">{{ benefit.title }}</h3>
              <p class="mb-0">
                {{ benefit.para }}
              </p>
            </div>
            <!--benefits-box-->
          </div>
          <!--col-md-4 col-lg-4 col-xl-4-->
        </div>
        <!--row-->
      </div>
      <!--container-->
    </section>
    <!--integrated-sec-->
  </div>
</template>

<script>
export default {
  name: "HomeView",
  components: {},

  data() {
    return {
      BenefitsData: [
        {
          title: "Easy to use",
          para: "Simplicity at its finest. Navigate effortlessly with our intuitive interface, catering to users of all levels. Experience hassle-free interactions and seamless transactions with our easy-to-use platform.",
        },
        {
          title: "Security",
          para: "We prioritize the highest standards of security, utilizing advanced encryption, biometric authentication, and proactive monitoring to safeguard your assets and data with unwavering commitment.",
        },
        {
          title: "Password protection",
          para: "Enhance your security with password protection. Set a strong and unique passphrase to ensure only authorized access to your account, adding an extra layer of safeguarding to your digital assets.",
        },
        {
          title: "Fast Transactions",
          para: "Experience swift transactions that match your pace. Our platform ensures rapid processing, enabling you to move funds seamlessly and capitalize on time-sensitive opportunities with efficiency and confidence.",
        },
        {
          title: "Safety",
          para: "With a focus on robust encryption, multi-factor authentication, and proactive monitoring, we ensure your assets and data remain secure, giving you peace of mind.",
        },
        {
          title: "Responsive Support",
          para: "Count on us whenever you need assistance. Our responsive support team is dedicated to addressing your inquiries promptly, ensuring a seamless experience and peace of mind on your journey.",
        },
      ],
    };
  },
};
</script>

<style scoped>
/**================= BANNER SEC CSS START =================**/
.banner-sec {
  min-height: 100vh;
  display: flex;
  align-items: center;
}
.banner-info h2 {
  color: #fff;
  font-size: 45px;
  line-height: 65px;
  font-weight: 700;
  text-transform: capitalize;
  padding-bottom: 25px;
}

.banner-info p {
  color: #c0c0c0;
  line-height: 26px;
  font-weight: 500;
  font-size: 16px;
  padding-bottom: 25px;
}

.banner-info .btn-primary {
  padding: 20px 38px;
  border-width: 1px;
  border: none;
  border-radius: 48px;
  background-color: #ce1db4;
  color: #fff;
  font-size: 18px;
  line-height: 1.111em;
  font-weight: 700;
  text-align: center;
}

.banner-info .btn-primary:active {
  background-color: #ce1db4;
}

/**================= BANNER SEC CSS FINISH =================**/

/**================= ABOUT SEC CSS START =================**/

.about-sec {
  background-color: #141414;
}

.upper-row {
  margin-bottom: 130px;
}

.wallet-info h2 {
  color: #fff;
  font-size: 35px;
  line-height: 60px;
  font-weight: 700;
  padding-bottom: 20px;
  text-transform: uppercase;
}

.wallet-info p {
  font-size: 15px;
  font-weight: 500;
  color: #c0c0c0;
  line-height: 26px;
  padding-bottom: 35px;
}

ul.list {
  margin: 0;
  padding: 0;
  list-style: none;
}

ul.list li {
  color: #fff;
  font-weight: 700;
  font-size: 18px;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

ul.list li span img {
  max-width: 25px;
}

ul.list li:nth-child(3) {
  margin-bottom: 0;
}

.card-info {
  border: 2px solid #2f2f2f;
  border-radius: 24px;
  overflow: hidden;
}

.security-info {
  padding: 32px 24px;
  text-align: center;
}

.security-info h3 {
  color: #fff;
  font-weight: 600;
  font-size: 25px;
}

.security-info h4 {
  text-transform: capitalize;
  font-weight: 600;
  color: #fff;
  font-size: 20px;
}

.border-box {
  border-right: 2px solid #2f2f2f;
  border-bottom: 2px solid #2f2f2f;
}

.border-box:nth-child(2) {
  border-right: none;
}
.border-box:nth-child(3) {
  border-bottom: none;
}
.border-box:nth-child(4) {
  border-right: none;
  border-bottom: none;
}

/**================= ABOUT SEC CSS FINISH =================**/

/**================= FEATURES SEC CSS START =================**/

.heading-info h2 {
  color: #fff;
  font-size: 35px;
  line-height: 55px;
  font-weight: 700;
  padding-bottom: 20px;
  text-transform: uppercase;
}

.button-box {
  text-align: end;
}

.button-box .btn-primary {
  padding: 20px 38px;
  border-width: 1px;
  border: none;
  border-radius: 48px;
  background-color: #ce1db4;
  color: #fff;
  font-size: 18px;
  line-height: 1.111em;
  font-weight: 700;
  text-align: center;
}

.button-box .btn-primary:active {
  background-color: #ce1db4;
}

.inner-wrapper {
  margin-top: 90px;
  border-radius: 15px;
  border: 2px solid #2f2f2f;
  overflow: hidden;
}

.image-info-box {
  padding: 94px 32px;
  position: relative;
  min-height: 450px;
  overflow: hidden;
}

.info-box h3 {
  color: #fff;
  font-size: 30px;
  line-height: 1.429em;
  font-weight: 600;
  text-transform: capitalize;
  padding-bottom: 12px;
}

.info-box p {
  font-size: 16px;
  font-weight: 500;
  color: #c0c0c0;
  line-height: 26px;
  padding-bottom: 35px;
}

.feature-border {
  border-right: 2px solid #2f2f2f;
  border-bottom: 2px solid #2f2f2f;
}

.feature-border:nth-child(2) {
  border-right: 0;
}

.feature-border:nth-child(3),
:nth-child(4),
:nth-child(5) {
  border-bottom: 0;
}

.image-box {
  position: absolute;
  bottom: 0px;
  right: -13px;
}

.feature-box {
  padding: 94px 32px;
}

.icon-box {
  text-align: center;
  margin-bottom: 20px;
}

.icon-info {
  text-align: center;
}

.icon-info h2 {
  font-size: 25px;
  text-transform: capitalize;
  color: #fff;
  font-weight: 700;
  padding-bottom: 15px;
}

.icon-info p {
  font-size: 16px;
  font-weight: 500;
  color: #c0c0c0;
  line-height: 26px;
}
/**================= FEATURES SEC CSS FINISH =================**/

/**================= CONTACT SEC CSS START =================**/

section.contact-sec {
  background-color: #141414;
}

.cta-image {
  text-align: center;
}

.cta-image img {
  max-width: 80%;
}

.cta-info h2 {
  font-size: 45px;
  line-height: 78px;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
  padding-bottom: 15px;
}

.cta-info p {
  font-size: 16px;
  font-weight: 500;
  color: #c0c0c0;
  line-height: 26px;
  text-align: justify;
  padding-bottom: 25px;
}

.cta-info .btn-primary {
  padding: 20px 38px;
  border-width: 1px;
  border: none;
  border-radius: 48px;
  background-color: #ce1db4;
  color: #fff;
  font-size: 18px;
  line-height: 1.111em;
  font-weight: 700;
  text-align: center;
}
/**================= CONTACT SEC CSS FINISH =================**/

/**================= BNEFITS SEC CSS START =================**/
.benefits-box {
  overflow: hidden;
  border-style: solid;
  border-width: 1px;
  border-color: #2f2f2f;
  border-radius: 18px;
  padding: 45px 25px;
  min-height: 270px;
}

.benefits-box h3 {
  font-size: 25px;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
  padding-bottom: 6px;
}

.benefits-box p {
  font-size: 15px;
  font-weight: 500;
  color: #c0c0c0;
  line-height: 26px;
}

/**================= BNEFITS SEC CSS FINISH =================**/

@media all and (min-width: 1200px) and (max-width: 1399px) {
  .wallet-info h2 br {
    display: none;
  }

  .wallet-info h2 {
    font-size: 50px;
    line-height: 76px;
  }

  .icon-info h2 {
    font-size: 20px;
  }

  .cta-info h2 {
    font-size: 40px;
    line-height: 65px;
  }
}

@media all and (min-width: 992px) and (max-width: 1199px) {
  .banner-info h2 {
    font-size: 50px;
    line-height: 60px;
  }

  .wallet-info h2 br {
    display: none;
  }

  .wallet-info h2 {
    font-size: 40px;
    line-height: 60px;
  }

  .security-info {
    padding: 24px 24px;
  }

  .security-info h4 {
    font-size: 17px;
  }
  .feature-border:nth-child(3) {
    border-bottom: 2px solid #2f2f2f;
  }

  .feature-border:nth-child(2) {
    border-right: 2px solid #2f2f2f;
  }

  .feature-box {
    padding: 30px 30px;
  }

  .cta-info h2 br {
    display: none;
  }

  .cta-info h2 {
    font-size: 35px;
    line-height: 50px;
  }
}

@media all and (min-width: 768px) and (max-width: 991px) {
  .banner-sec {
    min-height: auto;
  }
  .banner-info h2 {
    font-size: 35px;
    line-height: 48px;
  }

  .banner-info p br {
    display: none;
  }

  .wallet-info h2 {
    font-size: 30px;
    line-height: 50px;
  }

  .wallet-info h2 br {
    display: none;
  }

  .security-info h4 {
    font-size: 16px;
    line-height: 22px;
  }

  .heading-info h2 br {
    display: none;
  }

  .image-info-box {
    padding: 30px 30px;
  }

  .feature-border:nth-child(3) {
    border-bottom: 2px solid #2f2f2f;
  }

  .feature-border:nth-child(2) {
    border-right: 2px solid #2f2f2f;
  }

  .feature-box {
    padding: 30px 30px;
  }

  .icon-info h2 {
    font-size: 18px;
  }

  .cta-info h2 br {
    display: none;
  }

  .cta-info h2 {
    font-size: 25px;
    line-height: 43px;
  }

  .cta-info p {
    font-size: 15px;
  }

  .benefits-box h3 {
    font-size: 20px;
  }
}

@media all and (min-width: 320px) and (max-width: 767px) {
  .banner-info {
    text-align: center;
  }
  .banner-info h2 {
    font-size: 40px;
    line-height: 53px;
  }

  .banner-info p br {
    display: none;
  }

  .banner-image {
    margin-bottom: 20px;
  }

  .wallet-info h2 {
    font-size: 25px;
    line-height: 44px;
    text-align: center;
  }

  .wallet-info p {
    text-align: center;
  }

  .about-image {
    margin-bottom: 20px;
  }

  .border-box:nth-child(3) {
    border-bottom: 2px solid #2f2f2f;
  }

  .heading-info h2 br {
    display: none;
  }

  .heading-info h2 {
    font-size: 24px;
    line-height: 42px;
    text-align: center;
  }

  .button-box {
    text-align: center;
  }

  .image-info-box {
    padding: 45px 20px;
    min-height: 390px;
  }

  .info-box h3 {
    font-size: 20px;
    text-align: center;
  }

  .info-box p {
    text-align: center;
  }

  .info-box p br {
    display: none;
  }

  .feature-box {
    padding: 30px 30px;
  }

  .icon-info h2 {
    font-size: 18px;
  }

  .feature-border:nth-child(3),
  :nth-child(4) {
    border-bottom: 2px solid #2f2f2f;
  }

  .cta-info {
    text-align: center;
  }
  .cta-info h2 br {
    display: none;
  }

  .cta-info h2 {
    font-size: 24px;
    line-height: 45px;
  }

  .cta-info p {
    font-size: 14px;
  }

  .benefits-box {
    padding: 25px 25px;
  }

  .benefits-box h3 {
    font-size: 18px;
  }
}
</style>
